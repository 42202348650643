import { Center, OrbitControls, useGLTF, useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { RefObject, Suspense, useEffect, useMemo, useRef } from 'react';
import {
  AdditiveBlending,
  BufferAttribute,
  Color,
  Material,
  Mesh,
  RawShaderMaterial,
} from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import Credits from './components/Credits';
import firefliesFragmentShader from './shaders/fireflies/fragment.fs';
import firefliesVertexShader from './shaders/fireflies/vertex.vs';
import fogFragmentShader from './shaders/fog/fragment.fs';
import fogVertexShader from './shaders/fog/vertex.vs';
import portalFragmentShader from './shaders/portal/fragment.fs';
import portalVertexShader from './shaders/portal/vertex.vs';

type GLTFResult = GLTF & {
  nodes: {
    [model: string]: Mesh;
  };
  materials: {
    [name: string]: Material;
  };
};

export default function Experience() {
  useEffect(() => {
    const loading = document.querySelector('.container') as HTMLElement;
    loading.style.display = 'none';

    return () => {
      loading.style.display = 'block';
    };
  }, []);

  const portalMaterialRef = useRef() as RefObject<RawShaderMaterial>;
  const fogMaterialRef = useRef() as RefObject<RawShaderMaterial>;
  const firefliesMaterialRef = useRef() as RefObject<RawShaderMaterial>;

  useFrame((state, delta) => {
    if (portalMaterialRef.current) {
      portalMaterialRef.current.uniforms.uTime.value += delta;
    }
    if (fogMaterialRef.current) {
      fogMaterialRef.current.uniforms.uTime.value += delta;
    }
    if (firefliesMaterialRef.current) {
      firefliesMaterialRef.current.uniforms.uTime.value += delta;
    }
  });

  const fireflies = useMemo(() => {
    const firefliesCount = 40;
    const positionArray = new Float32Array(firefliesCount * 3);
    const scaleArray = new Float32Array(firefliesCount);

    for (let i = 0; i < firefliesCount; i++) {
      positionArray[i * 3 + 0] = (Math.random() - 0.5) * 2;
      positionArray[i * 3 + 1] = Math.random() * 1.5 + 0.2;
      positionArray[i * 3 + 2] = (Math.random() - 0.5) * 2;

      scaleArray[i] = (Math.random() + 1) / 2;
    }

    return { positionArray, scaleArray };
  }, []);

  const { nodes } = useGLTF('/well-scene/portal.glb') as unknown as GLTFResult;
  const bakedTexture = useTexture('/well-scene/baked.webp');

  return (
    <Suspense fallback={null}>
      <color args={['#000000']} attach="background" />

      <OrbitControls makeDefault />

      <Center>
        <mesh geometry={nodes.Baked.geometry}>
          <meshBasicMaterial map={bakedTexture} map-flipY={false} />
        </mesh>

        <mesh
          geometry={nodes.PortalLight.geometry}
          position={nodes.PortalLight.position}
        >
          <rawShaderMaterial
            ref={portalMaterialRef}
            vertexShader={portalVertexShader}
            fragmentShader={portalFragmentShader}
            uniforms={{
              uTime: { value: 0 },
              uColorStart: { value: new Color('#bcfebe') },
              uColorEnd: { value: new Color('#00ff00') },
            }}
            blending={AdditiveBlending}
          />
        </mesh>

        <mesh geometry={nodes.Fog.geometry} position={nodes.Fog.position}>
          <rawShaderMaterial
            ref={fogMaterialRef}
            vertexShader={fogVertexShader}
            fragmentShader={fogFragmentShader}
            uniforms={{
              uTime: { value: 0 },
              uColorStart: { value: new Color(0x000000) },
              uColorEnd: { value: new Color(0xeeeeee) },
            }}
            transparent={true}
            blending={AdditiveBlending}
            depthWrite={false}
          />
        </mesh>

        <points>
          <bufferGeometry
            attributes={{
              position: new BufferAttribute(fireflies.positionArray, 3),
              aScale: new BufferAttribute(fireflies.scaleArray, 1),
            }}
          />
          <rawShaderMaterial
            ref={firefliesMaterialRef}
            uniforms={{
              uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
              uSize: { value: 120 },
              uTime: { value: 0 },
            }}
            vertexShader={firefliesVertexShader}
            fragmentShader={firefliesFragmentShader}
            transparent={true}
            blending={AdditiveBlending}
            depthWrite={false}
          />
        </points>
      </Center>
      <Credits />
    </Suspense>
  );
}
