import { Html, Text } from '@react-three/drei';
import { RefObject, useEffect, useRef, useState } from 'react';
import roboto from '@fontsource/roboto/files/roboto-latin-400-normal.woff';

export default function Credits() {
  const tooltipRef = useRef() as RefObject<HTMLParagraphElement>;

  const eventHandler = () => {
    window.open('https://github.com/cowoder', '_blank');
  };

  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto';

    if (tooltipRef.current) {
      tooltipRef.current.style.display = hovered ? 'block' : 'none';
    }

    return () => {
      document.body.style.cursor = 'auto';
      if (tooltipRef.current) {
        tooltipRef.current.style.display = 'none';
      }
    };
  }, [hovered]);

  return (
    <group position={[15, 3, -27]}>
      <Text
        font={roboto}
        fontSize={0.5}
        rotation-y={-1}
        maxWidth={2}
        textAlign="center"
        color={hovered ? '#00ff00' : '#ffffff'}
        onClick={eventHandler}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
      >
        by cowoder
      </Text>
      <Html>
        <p ref={tooltipRef} className="tooltip">
          Go to github
        </p>
      </Html>
    </group>
  );
}
