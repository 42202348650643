import './style.css';

import { Canvas } from '@react-three/fiber';
import React from 'react';
import ReactDOM from 'react-dom/client';

import Experience from './Experience.js';

const root = ReactDOM.createRoot(
  document.querySelector('#root') as HTMLCanvasElement
);

root.render(
  <React.StrictMode>
    <Canvas
      flat
      camera={{
        fov: 45,
        near: 0.1,
        far: 200,
        position: [1, 2, 6],
      }}
    >
      <Experience />
    </Canvas>
  </React.StrictMode>
);
